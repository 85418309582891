<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-18 09:14:38
 * @LastEditors: wenwen
 * @LastEditTime: 2024-02-26 09:08:22
-->
<template>
  <div class="container-warp">
      <div class="plan-type-box">
        <com-title>计划类型</com-title>
        <div class="plan-type-box-info">计划类型是指根据培训内容，培训对象对培训分类，例如新员工入职培训，人力资源培训等</div>
        <div class="label-box">
          <el-tag
            v-for="item in planLabelList"
            :key="item.id"
            :type="String(item.id)"
            effect="plain"
            size="medium">
            {{ item.categoryName }}
          </el-tag>
          <el-tag size="medium" @click="_openTypeDialog('plan')">
            <i class="el-icon-plus"></i>
            修改
          </el-tag>
        </div>
      </div>
      <div class="plan-type-box" style="margin-top:25px;">
        <com-title>课程类型</com-title>
        <div class="plan-type-box-info">课程类别是指按照课程设计的不同性质和特点形成的课程类型，如实训课程、技能理论等</div>
        <div class="label-box">
          <el-tag
            v-for="item in courseTypeList"
            :key="item.id"
            :type="String(item.id)"
            effect="plain"
            size="medium">
            {{ item.categoryName }}
          </el-tag>
          <el-tag size="medium"  @click="_openTypeDialog('course')">
            <i class="el-icon-plus"></i>
            修改
          </el-tag>
        </div>
      </div>
      <el-dialog
        class="type-dialog-box"
        width="400px"
        :title="title"
        :visible.sync="typeDialog"
        :close-on-click-modal="false"
        >

        <div class="input-item" v-for="(item,index) in editShowData" :key="index">
          <el-input style="width: 320px;" placeholder="最多输入8个字符" :maxlength="8" v-model="item.categoryName"></el-input>
          <i
          v-if="editShowData.length>1"
          @click="deleteInput(item,index)"
          style="color:#F04134;margin-left:8px;"
          class="iconfont icon-icon_delete"></i>
        </div>
        <div class="add-btn">
          <el-button icon="el-icon-plus" type="primary" round plain @click="addData">点击添加</el-button>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="typeDialog = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
      </div>
      </el-dialog>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import { getFindCustomizeTypeApi, submitFindCustomizeTypeApi, deleteFindCustomizeTypeAPi } from '@/api/settings'
export default {
  components: {
    comTitle
  },
  data () {
    return {
      planLabelList: [],
      courseTypeList: [],
      typeDialog: false,
      title: '', // 弹窗title
      editShowData: [
        {
          categoryName: ''
        }
      ], // 弹窗显示数据
      type: '',
      ids: []
    }
  },
  created () {
    this.getFindCustomizeType()
  },
  methods: {
    getFindCustomizeType () {
      getFindCustomizeTypeApi({}).then(res => {
        if (res.code === 200) {
          this.courseTypeList = res.data.scheduleType
          this.planLabelList = res.data.planType
        }
      })
    },
    _openTypeDialog (type) {
      if (!this.editShowData.length) {
        this.editShowData.push({
          categoryName: '',
          id: '',
          type: this.type
        })
      } else {
        this.editShowData = []
      }
      if (type === 'plan') {
        this.type = '1'
        this.title = '计划类型'
        this.editShowData = JSON.parse(JSON.stringify(this.planLabelList))
      } else {
        this.type = '0'
        this.title = '课程类型'
        this.editShowData = JSON.parse(JSON.stringify(this.courseTypeList))
      }
      this.typeDialog = true
    },
    deleteInput (item, index) {
      // 删除输入框
      if (item.id) {
        this.ids.push(item.id)
      }
      this.editShowData.splice(index, 1)
    },
    addData () {
      // 添加数据
      this.editShowData.push({
        categoryName: '',
        id: '',
        type: this.type
      })
    },
    submit () {
      // 提交
      const arr = []
      this.editShowData.forEach(item => {
        if (item.categoryName) {
          arr.push(item)
        }
      })
      if (this.ids.length) {
        deleteFindCustomizeTypeAPi(this.ids).then(res => {
          if (res.code === 200) {
          }
        })
      }
      submitFindCustomizeTypeApi(arr).then(res => {
        if (res.code === 200) {
          this.typeDialog = false
          this.getFindCustomizeType()
          this.$message({
            message: '提交修改成功',
            type: 'success'
          })
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
  .container-warp{
    padding: 21px 24px;
    width: 100%;
    min-height: 800px;
    background-color: #ffffff;
    border-radius: 4px;
  }
  .plan-type-box{
    .plan-type-box-info{
      margin: 16px 0 12px;
      font-size: 14px;
      color: #333333;
    }
  }
  /deep/.el-tag {
    padding:0 16px;
    margin-right: 16px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 28px;
  }
  .type-dialog-box{

    .input-item{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .el-icon-delete{
        margin-left: 8px;
        color: #ff5050;
        font-size: 20px;
      }
    }
    .add-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-bottom: 32px;
    }
  }
  /deep/.el-dialog__header{
      padding: 16px 24px;
    }
    /deep/.el-dialog__title{
      font-size: 16px;
      font-weight: 500;
      color: rgba(0,0,0,0.9);
    }
    /deep/.el-dialog__body{
      padding: 24px 24px;
    }
</style>
